.entry-header {
	text-align: center; }
.bt-site {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	min-height: 100vh;
	.admin-bar & {
		min-height: calc(100vh - 32px);
		@media (max-width: 782px) {
			min-height: calc(100vh - 46px); } } }
.site-main {
	flex: 1 1 auto; }
.entry-footer {
	height: auto; }

.elementor-section.elementor-section-stretched {
	max-width: 100%; }

.elementor-slides .swiper-slide-bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; }

.swiper-wrapper .swiper-slide {
	padding: 0; }

.swiper-wrapper .swiper-slide-contents {
	margin: 0 auto;
	width: 100%; }




.main-slider {
	.swiper-wrapper {
		.swiper-slide-contents {
			>* {
				max-width: 535px; } } }
	.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
		line-height: 0;
		bottom: 31px; }
	.swiper-pagination-bullet {
		width: 17px;
		height: 17px;
		border-radius: 50%;
		background: transparent;
		border: 2px solid rgba(255, 255, 255, 0.69);
		opacity: 1;
		box-sizing: border-box;
		transition: all 0.3s; }
	.swiper-pagination-bullet-active {
		border: 1px solid rgba(112, 112, 112, 0.69);
		background-color: rgba(255, 255, 255, 0.7); }
	.elementor-slides .swiper-slide-inner .elementor-slide-button {
		text-align: center;
		padding: 21px 25px;
		min-width: 308px;
		@media (max-width: 767px) {
			min-width: unset;
			padding: 10px 25px; } }
	.elementor-slide-heading {
		padding-bottom: 36px;
		position: relative;
		&:before {
			content: '';
			width: 154px;
			height: 2px;
			background-color: #6a7480;
			position: absolute;
			top: 100%;
			left: 0; }
		@media (max-width: 767px) {
			padding-bottom: 20px; } } }
.main-banner {
	.wp-caption {
		position: relative;
		a {
			display: block;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(#000, 0.35); }
			img {
				display: block;
				position: relative; } } }
	.widget-image-caption.wp-caption-text {
		position: absolute;
		left: 52px;
		bottom: 21px;
		z-index: 2;
		@media (max-width: 767px) {
			left: 30px; } } }


.main-heading {
	.elementor-heading-title {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			left: 50%;
			top: 100%;
			transform: translateX(-50%);
			width: 154px;
			height: 2px;
			background-color: #f37725; } } }


.site-main.page {
	ul:not([class]), ol:not([class]) {
		margin: 0 0 1em;
		padding: 0 0 0 1.5em;
		[dir="rtl"] & {
			padding: 0 1.5em 0 0; } }
	ul:not([class]) {
		list-style: disc; }
	ol:not([class]) {
		list-style: decimal; } }







