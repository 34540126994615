.bt-icon-menu {
    display: inline-block;
    margin: 0;
    cursor: pointer;
    height: 40px;
    width: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    background-color: #fff;

    &:before {
        content: '';
        background-image: url('../svg/menu.svg');
        height: 100%;
        width: 100%;
        display: inline-block;
        background-position: center;
        background-size: cover; } }

.bt-navbar {
    background: #09c;
    width: 100%;
    height: 75px;
    position: absolute;
    z-index: 99999;
    box-shadow: 0 0 10px #000; }

.bt-search {
    position: absolute;
    background: darken(#0099cc, 10%);
    width: 100%;
    left: 0;
    top: -100%;
    z-index: 999;
    transition-duration: 1s;
    box-shadow: 0 0 10px #000;
    padding: 15px;
    line-height: 75px;

    h1 {
        float: left;
        margin: 0;
        color: #fff;
        padding-right: 25px;
        font-weight: normal; }

    input[type='search'] {
        padding: 10px;
        width: 250px; } }

.bt-down {
    top: 74px; }
