@charset "UTF-8";
@import "mixin";
@import "normalize";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

$font: 'Roboto', sans-serif;

$color: #000;
$link: #F37725;
$brand: #F37725;
$base-fz: 18px;
$base-lh: 1.222;

$w: 1640px;
