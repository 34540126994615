@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

img, fieldset, a img {
  border: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  * {
    -webkit-text-size-adjust: none;
  }
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 48px;
  height: 30px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 48px;
  height: 4px;
  background-color: #000;
  border-radius: 2px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -13px;
}

.hamburger-inner::after {
  bottom: -13px;
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner::before {
  top: 13px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}

.hamburger--slider .hamburger-inner::after {
  top: 26px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 13px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-6.85714px, -9px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -26px, 0) rotate(-90deg);
}

body {
  font: 18px/1.222 "Roboto", sans-serif;
  color: #000;
}

select, input, textarea, button {
  font: 99% "Roboto", sans-serif;
}

pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

a {
  text-decoration: underline;
  color: #F37725;
}

a:visited {
  color: #F37725;
}

a:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, b, strong {
  font-weight: bold;
}

em, i {
  font-style: italic;
}

h1, h2, h3, h4, h5, h6, p, blockquote, ul, ol, dl, address, table {
  margin-bottom: 1em;
}

.menu {
  margin: 0;
  list-style: none;
}

h6 {
  font-size: 100%;
}

h5 {
  font-size: 115%;
}

h4 {
  font-size: 125%;
}

h3 {
  font-size: 150%;
}

h2 {
  font-size: 175%;
}

h1 {
  font-size: 200%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

html {
  min-width: 320px;
}

body, html {
  height: auto;
}

.wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1670px;
  margin: 0 auto;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  height: auto;
  right: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.bt-contact-us {
  height: 100%;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  background-color: #f1f1f1;
}

.bt-contact-us p {
  margin-bottom: 20px;
  flex-basis: 100%;
  text-align: center;
}

.wpcf7-form .wpcf7-form-control.wpcf7-textarea {
  height: 135px;
}

.bt-site.bt-header-fixed {
  padding-top: 90px;
}

.bt-site-header {
  transition: all .4s ease;
  position: static;
  top: -60px;
}

.bt-site-header.bt-fixed {
  position: fixed;
  z-index: 99;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: auto;
}

.bt-site-header img {
  transition: all .4s ease;
}

.bt-site-header.bt-fixed img {
  height: 100%;
  width: auto;
  max-height: 45px;
  padding-left: 10px;
}

.bt-site-header.bt-fixed .bt-spoiler-block, .bt-site-header.bt-fixed .site-branding {
  height: 100%;
}

.wrp-navigation {
  flex-basis: 100%;
}

.bt-menu-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 8;
  display: none;
  width: 100vw;
}

.bt-menu__close {
  position: absolute;
  left: 25px;
  color: #fff;
  cursor: pointer;
  font-size: 48px;
  line-height: 26px;
  top: 46px;
}

.bt-menu-open .bt-menu-overlay {
  display: block;
}

@media (max-width: 600px) {
  .bt-contact-us {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .footer-bot .container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer-bot .container .footer-bot__item {
    text-align: center;
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 15px;
  }
  .footer-bot .container .footer-bot__item:last-of-type {
    margin-bottom: 0;
  }
  .footer-bot .container .footer-bot__item .menu {
    justify-content: center;
  }
  .footer-bot .container .footer-bot__item .menu .menu-item:last-of-type {
    margin-right: 0;
  }
}

html, body, .st-container, .st-pusher {
  height: 100%;
}

html.admin-bar {
  height: calc(100% - 32px);
}

@media (max-width: 782px) {
  html.admin-bar {
    height: calc(100% - 46px);
  }
}

.st-content {
  position: relative;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

.st-content-inner {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.st-container {
  position: relative;
}

.st-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 110;
  visibility: hidden;
  width: 0;
  height: 100%;
  background: #F37725;
  color: #ffffff;
  transition: all 0.5s;
  box-sizing: border-box;
  padding: 0;
  overflow-x: hidden;
}

.st-menu .menu {
  height: 100%;
  overflow: auto;
}

.st-menu .menu a {
  display: block;
  padding: 10px 25px;
  text-decoration: none;
  color: #ffffff;
}

.st-menu .menu li + li {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.st-menu .st-menu-inner {
  height: 100%;
  padding: 15px 0;
  overflow: auto;
}

.st-menu .user_nav {
  margin-top: 30px;
}

.st-menu .user_nav .menu li + li {
  border: none;
}

.st-menu .user_nav .menu > li {
  margin: 0;
}

.st-menu .user_nav .menu .icon-search {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cpath fill='%23fff' d='M19.057 20.217l-5.07-5.07 1.259-1.259 5.07 5.07a1.409 1.409 0 0 1-1.26 1.259zM8.009 15.305c-4.023 0-7.296-3.273-7.296-7.297C.713 3.985 3.986.712 8.009.712c4.024 0 7.297 3.273 7.297 7.296 0 4.024-3.273 7.297-7.297 7.297zm7.22-2.423l-.922.93-.396-.4a7.98 7.98 0 0 0 2.104-5.404C16.015 3.592 12.424 0 8.008 0 3.592 0 0 3.592 0 8.008c0 4.417 3.593 8.009 8.009 8.009a7.98 7.98 0 0 0 5.403-2.103l.4.4-.832.865L18.77 21h.147C20.084 21 21 19.985 21 18.818v-.148z'/%3E%3C/svg%3E");
}

.st-menu .user_nav .menu .icon-user {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cpath fill='%23fff' d='M17.437 17.415c-.767-.982-3.152-2.058-4.623-2.654-.164-.067-.342-.61-.369-1.392a3.17 3.17 0 0 0 .535-.687c.058-.096.116-.187.174-.277.185-.29.376-.588.541-.99a.951.951 0 0 0 .41-.392c.317-.58.57-1.649.408-2.105a.622.622 0 0 0-.332-.364c-.013-1.894-.863-3.347-2.297-3.915a10.404 10.404 0 0 0-1.41-.492c-.254-.07-.386-.186-.397-.191a.35.35 0 0 0-.414-.286c-.28.056-.521.421-.615.653a1.098 1.098 0 0 0-.068.24c-.409.064-.948.27-1.104.834a.375.375 0 0 0-.019.155c-.513.582-.998 1.518-1.008 2.983a.634.634 0 0 0-.37.38c-.164.463.084 1.511.407 2.104.101.185.265.33.446.406.162.398.35.693.532.98.054.086.11.173.166.264.19.313.384.55.599.731-.046.784-.244 1.31-.38 1.35-.476.142-3.786 1.412-4.687 2.664A9.769 9.769 0 0 1 .7 10.5C.7 5.096 5.096.7 10.5.7c5.404 0 9.8 4.396 9.8 9.8a9.769 9.769 0 0 1-2.863 6.915zm-13.356.48zm4.37-2.475c.724-.216.865-1.457.885-2.177a.348.348 0 0 0-.151-.297c-.197-.136-.374-.34-.558-.641-.058-.096-.116-.187-.172-.276-.205-.322-.382-.6-.531-1.015a.34.34 0 0 0-.312-.23.22.22 0 0 1-.112-.1c-.294-.541-.402-1.286-.368-1.504l.063-.002a.35.35 0 0 0 .359-.363C7.49 7.126 8.11 6.22 8.64 5.758a.351.351 0 0 0 .097-.389c.24-.134.613-.126.616-.127l.02-.35.295-.183c-.007-.034.013-.095.044-.16.168.126.378.218.575.272.608.169 1.05.343 1.326.464 1.249.495 1.928 1.781 1.863 3.531a.351.351 0 0 0 .343.363c.017 0 .03.001.04.003.032.216-.08.975-.369 1.505a.239.239 0 0 1-.11.103.35.35 0 0 0-.28.226c-.153.412-.33.69-.537 1.012l-.182.29c-.174.287-.34.488-.504.616a.35.35 0 0 0-.136.279c.005.7.116 1.915.81 2.196 2.279.924 4.016 1.914 4.375 2.48A9.757 9.757 0 0 1 10.5 20.3a9.757 9.757 0 0 1-6.419-2.404c.4-.798 3.205-2.126 4.37-2.476zM10.5 0C4.71 0 0 4.71 0 10.5S4.71 21 10.5 21 21 16.29 21 10.5 16.29 0 10.5 0z'/%3E%3C/svg%3E");
}

.st-menu .user_nav .menu .bp-login-link span {
  color: #ffffff;
}

.st-pusher {
  position: relative;
  left: 0;
  z-index: 99;
  height: 100%;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

.st-pusher::after {
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  background: rgba(0, 0, 0, 0.2);
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  z-index: 100;
}

.st-menu-open {
  overflow: hidden;
}

.st-menu-open .st-menu {
  visibility: visible;
  transition: width 0.5s;
  width: 300px;
}

.st-menu-open .st-pusher .st-content {
  transform: translate3d(-300px, 0, 0);
}

.st-menu-open .st-pusher::after {
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.bt-site-header {
  border-top: 3px solid #F37725;
  padding: 2px 0 5px;
  background: #ffffff;
}

.bt-site-header .container {
  max-width: 1950px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
}

.bt-site-header.bt-fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.bt-site-header.bt-fixed .container {
  min-height: 60px;
}

.bt-site-header .custom-logo-link img {
  width: 230px;
  height: auto;
  max-height: 80px;
}

.bt-site-header .custom-logo-link img.lazyloading, .bt-site-header .custom-logo-link img.lazyload {
  width: auto;
}

.main-navigation .menu {
  display: flex;
  justify-content: center;
  font: 400 18px/22px "Roboto", sans-serif;
  text-transform: uppercase;
}

.main-navigation .menu a {
  color: #1F3042;
  text-decoration: none;
}

.main-navigation .menu a:hover {
  color: #F37725;
}

.main-navigation .menu > li {
  margin: 0 33px;
}

.main-navigation .menu .current-menu-item a {
  color: #F37725;
}

.user_nav .menu {
  display: flex;
}

.user_nav .menu a {
  display: inline-block;
  box-sizing: border-box;
}

.user_nav .menu a:hover .icon-search {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cpath fill='%23F37725' d='M19.057 20.217l-5.07-5.07 1.259-1.259 5.07 5.07a1.409 1.409 0 0 1-1.26 1.259zM8.009 15.305c-4.023 0-7.296-3.273-7.296-7.297C.713 3.985 3.986.712 8.009.712c4.024 0 7.297 3.273 7.297 7.296 0 4.024-3.273 7.297-7.297 7.297zm7.22-2.423l-.922.93-.396-.4a7.98 7.98 0 0 0 2.104-5.404C16.015 3.592 12.424 0 8.008 0 3.592 0 0 3.592 0 8.008c0 4.417 3.593 8.009 8.009 8.009a7.98 7.98 0 0 0 5.403-2.103l.4.4-.832.865L18.77 21h.147C20.084 21 21 19.985 21 18.818v-.148z'/%3E%3C/svg%3E");
}

.user_nav .menu a:hover .icon-user {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cpath fill='%23F37725' d='M17.437 17.415c-.767-.982-3.152-2.058-4.623-2.654-.164-.067-.342-.61-.369-1.392a3.17 3.17 0 0 0 .535-.687c.058-.096.116-.187.174-.277.185-.29.376-.588.541-.99a.951.951 0 0 0 .41-.392c.317-.58.57-1.649.408-2.105a.622.622 0 0 0-.332-.364c-.013-1.894-.863-3.347-2.297-3.915a10.404 10.404 0 0 0-1.41-.492c-.254-.07-.386-.186-.397-.191a.35.35 0 0 0-.414-.286c-.28.056-.521.421-.615.653a1.098 1.098 0 0 0-.068.24c-.409.064-.948.27-1.104.834a.375.375 0 0 0-.019.155c-.513.582-.998 1.518-1.008 2.983a.634.634 0 0 0-.37.38c-.164.463.084 1.511.407 2.104.101.185.265.33.446.406.162.398.35.693.532.98.054.086.11.173.166.264.19.313.384.55.599.731-.046.784-.244 1.31-.38 1.35-.476.142-3.786 1.412-4.687 2.664A9.769 9.769 0 0 1 .7 10.5C.7 5.096 5.096.7 10.5.7c5.404 0 9.8 4.396 9.8 9.8a9.769 9.769 0 0 1-2.863 6.915zm-13.356.48zm4.37-2.475c.724-.216.865-1.457.885-2.177a.348.348 0 0 0-.151-.297c-.197-.136-.374-.34-.558-.641-.058-.096-.116-.187-.172-.276-.205-.322-.382-.6-.531-1.015a.34.34 0 0 0-.312-.23.22.22 0 0 1-.112-.1c-.294-.541-.402-1.286-.368-1.504l.063-.002a.35.35 0 0 0 .359-.363C7.49 7.126 8.11 6.22 8.64 5.758a.351.351 0 0 0 .097-.389c.24-.134.613-.126.616-.127l.02-.35.295-.183c-.007-.034.013-.095.044-.16.168.126.378.218.575.272.608.169 1.05.343 1.326.464 1.249.495 1.928 1.781 1.863 3.531a.351.351 0 0 0 .343.363c.017 0 .03.001.04.003.032.216-.08.975-.369 1.505a.239.239 0 0 1-.11.103.35.35 0 0 0-.28.226c-.153.412-.33.69-.537 1.012l-.182.29c-.174.287-.34.488-.504.616a.35.35 0 0 0-.136.279c.005.7.116 1.915.81 2.196 2.279.924 4.016 1.914 4.375 2.48A9.757 9.757 0 0 1 10.5 20.3a9.757 9.757 0 0 1-6.419-2.404c.4-.798 3.205-2.126 4.37-2.476zM10.5 0C4.71 0 0 4.71 0 10.5S4.71 21 10.5 21 21 16.29 21 10.5 16.29 0 10.5 0z'/%3E%3C/svg%3E");
}

.user_nav .menu > li {
  margin: 0 12px;
}

.user_nav .menu .icon-search {
  width: 21px;
  height: 21px;
  display: inline-block;
  font-size: 0;
  line-height: 21px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cpath d='M19.057 20.217l-5.07-5.07 1.259-1.259 5.07 5.07a1.409 1.409 0 0 1-1.26 1.259zM8.009 15.305c-4.023 0-7.296-3.273-7.296-7.297C.713 3.985 3.986.712 8.009.712c4.024 0 7.297 3.273 7.297 7.296 0 4.024-3.273 7.297-7.297 7.297zm7.22-2.423l-.922.93-.396-.4a7.98 7.98 0 0 0 2.104-5.404C16.015 3.592 12.424 0 8.008 0 3.592 0 0 3.592 0 8.008c0 4.417 3.593 8.009 8.009 8.009a7.98 7.98 0 0 0 5.403-2.103l.4.4-.832.865L18.77 21h.147C20.084 21 21 19.985 21 18.818v-.148z'/%3E%3C/svg%3E") no-repeat;
  background-size: contain;
}

.user_nav .menu .icon-user {
  width: 21px;
  height: 21px;
  display: inline-block;
  font-size: 0;
  line-height: 21px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cpath d='M17.437 17.415c-.767-.982-3.152-2.058-4.623-2.654-.164-.067-.342-.61-.369-1.392a3.17 3.17 0 0 0 .535-.687c.058-.096.116-.187.174-.277.185-.29.376-.588.541-.99a.951.951 0 0 0 .41-.392c.317-.58.57-1.649.408-2.105a.622.622 0 0 0-.332-.364c-.013-1.894-.863-3.347-2.297-3.915a10.404 10.404 0 0 0-1.41-.492c-.254-.07-.386-.186-.397-.191a.35.35 0 0 0-.414-.286c-.28.056-.521.421-.615.653a1.098 1.098 0 0 0-.068.24c-.409.064-.948.27-1.104.834a.375.375 0 0 0-.019.155c-.513.582-.998 1.518-1.008 2.983a.634.634 0 0 0-.37.38c-.164.463.084 1.511.407 2.104.101.185.265.33.446.406.162.398.35.693.532.98.054.086.11.173.166.264.19.313.384.55.599.731-.046.784-.244 1.31-.38 1.35-.476.142-3.786 1.412-4.687 2.664A9.769 9.769 0 0 1 .7 10.5C.7 5.096 5.096.7 10.5.7c5.404 0 9.8 4.396 9.8 9.8a9.769 9.769 0 0 1-2.863 6.915zm-13.356.48zm4.37-2.475c.724-.216.865-1.457.885-2.177a.348.348 0 0 0-.151-.297c-.197-.136-.374-.34-.558-.641-.058-.096-.116-.187-.172-.276-.205-.322-.382-.6-.531-1.015a.34.34 0 0 0-.312-.23.22.22 0 0 1-.112-.1c-.294-.541-.402-1.286-.368-1.504l.063-.002a.35.35 0 0 0 .359-.363C7.49 7.126 8.11 6.22 8.64 5.758a.351.351 0 0 0 .097-.389c.24-.134.613-.126.616-.127l.02-.35.295-.183c-.007-.034.013-.095.044-.16.168.126.378.218.575.272.608.169 1.05.343 1.326.464 1.249.495 1.928 1.781 1.863 3.531a.351.351 0 0 0 .343.363c.017 0 .03.001.04.003.032.216-.08.975-.369 1.505a.239.239 0 0 1-.11.103.35.35 0 0 0-.28.226c-.153.412-.33.69-.537 1.012l-.182.29c-.174.287-.34.488-.504.616a.35.35 0 0 0-.136.279c.005.7.116 1.915.81 2.196 2.279.924 4.016 1.914 4.375 2.48A9.757 9.757 0 0 1 10.5 20.3a9.757 9.757 0 0 1-6.419-2.404c.4-.798 3.205-2.126 4.37-2.476zM10.5 0C4.71 0 0 4.71 0 10.5S4.71 21 10.5 21 21 16.29 21 10.5 16.29 0 10.5 0z'/%3E%3C/svg%3E") no-repeat;
  background-size: contain;
}

.bt-mmenu-trigger {
  display: inline-block;
}

.bt-header-row .elementor-row {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bt-header-row .elementor-column {
  width: auto !important;
}

.bt-header-row .bt-header-nav-column {
  flex: 1 1 auto;
}

.bt-header-row .bt-header-nav-column .elementor-nav-menu--layout-horizontal {
  justify-content: center;
}

.elementor-location-header {
  position: relative;
  z-index: 100;
  background: #ffffff;
  transition: transform 0.3s;
}

.elementor-location-header.bt-header-absolute {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.admin-bar .elementor-location-header.bt-header-absolute {
  top: 32px;
}

@media (max-width: 782px) {
  .admin-bar .elementor-location-header.bt-header-absolute {
    top: 46px;
  }
}

.elementor-location-header.bt-header-hidden {
  transform: translateY(-100%);
}

.elementor-location-header.bt-fixed {
  position: fixed;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.admin-bar .elementor-location-header.bt-fixed {
  top: 32px;
}

@media (max-width: 782px) {
  .admin-bar .elementor-location-header.bt-fixed {
    top: 46px;
  }
}

@media (max-width: 600px) {
  .admin-bar .elementor-location-header.bt-fixed {
    top: 0;
  }
}

.elementor-editor-active .header-holder {
  display: none;
}

body:not(.elementor-editor-active) .bt-header-sidebar {
  position: absolute !important;
  top: 100%;
  left: 0;
  max-height: calc(100vh - 90px);
  display: none;
  overflow: auto;
}

body:not(.elementor-editor-active) .bt-header-sidebar .user_nav .menu > li {
  margin: 0;
}

body:not(.elementor-editor-active) .bt-header-sidebar .user_nav .menu a {
  padding: 10px 25px;
  color: #ffffff;
}

body:not(.elementor-editor-active) .bt-header-sidebar .user_nav .menu .bp-login-link span {
  color: #ffffff;
}

body:not(.elementor-editor-active) .bt-header-sidebar .user_nav .menu .icon-search {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cpath fill='%23fff' d='M19.057 20.217l-5.07-5.07 1.259-1.259 5.07 5.07a1.409 1.409 0 0 1-1.26 1.259zM8.009 15.305c-4.023 0-7.296-3.273-7.296-7.297C.713 3.985 3.986.712 8.009.712c4.024 0 7.297 3.273 7.297 7.296 0 4.024-3.273 7.297-7.297 7.297zm7.22-2.423l-.922.93-.396-.4a7.98 7.98 0 0 0 2.104-5.404C16.015 3.592 12.424 0 8.008 0 3.592 0 0 3.592 0 8.008c0 4.417 3.593 8.009 8.009 8.009a7.98 7.98 0 0 0 5.403-2.103l.4.4-.832.865L18.77 21h.147C20.084 21 21 19.985 21 18.818v-.148z'/%3E%3C/svg%3E") no-repeat;
}

body:not(.elementor-editor-active) .bt-header-sidebar .user_nav .menu .icon-user {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cpath fill='%23fff' d='M17.437 17.415c-.767-.982-3.152-2.058-4.623-2.654-.164-.067-.342-.61-.369-1.392a3.17 3.17 0 0 0 .535-.687c.058-.096.116-.187.174-.277.185-.29.376-.588.541-.99a.951.951 0 0 0 .41-.392c.317-.58.57-1.649.408-2.105a.622.622 0 0 0-.332-.364c-.013-1.894-.863-3.347-2.297-3.915a10.404 10.404 0 0 0-1.41-.492c-.254-.07-.386-.186-.397-.191a.35.35 0 0 0-.414-.286c-.28.056-.521.421-.615.653a1.098 1.098 0 0 0-.068.24c-.409.064-.948.27-1.104.834a.375.375 0 0 0-.019.155c-.513.582-.998 1.518-1.008 2.983a.634.634 0 0 0-.37.38c-.164.463.084 1.511.407 2.104.101.185.265.33.446.406.162.398.35.693.532.98.054.086.11.173.166.264.19.313.384.55.599.731-.046.784-.244 1.31-.38 1.35-.476.142-3.786 1.412-4.687 2.664A9.769 9.769 0 0 1 .7 10.5C.7 5.096 5.096.7 10.5.7c5.404 0 9.8 4.396 9.8 9.8a9.769 9.769 0 0 1-2.863 6.915zm-13.356.48zm4.37-2.475c.724-.216.865-1.457.885-2.177a.348.348 0 0 0-.151-.297c-.197-.136-.374-.34-.558-.641-.058-.096-.116-.187-.172-.276-.205-.322-.382-.6-.531-1.015a.34.34 0 0 0-.312-.23.22.22 0 0 1-.112-.1c-.294-.541-.402-1.286-.368-1.504l.063-.002a.35.35 0 0 0 .359-.363C7.49 7.126 8.11 6.22 8.64 5.758a.351.351 0 0 0 .097-.389c.24-.134.613-.126.616-.127l.02-.35.295-.183c-.007-.034.013-.095.044-.16.168.126.378.218.575.272.608.169 1.05.343 1.326.464 1.249.495 1.928 1.781 1.863 3.531a.351.351 0 0 0 .343.363c.017 0 .03.001.04.003.032.216-.08.975-.369 1.505a.239.239 0 0 1-.11.103.35.35 0 0 0-.28.226c-.153.412-.33.69-.537 1.012l-.182.29c-.174.287-.34.488-.504.616a.35.35 0 0 0-.136.279c.005.7.116 1.915.81 2.196 2.279.924 4.016 1.914 4.375 2.48A9.757 9.757 0 0 1 10.5 20.3a9.757 9.757 0 0 1-6.419-2.404c.4-.798 3.205-2.126 4.37-2.476zM10.5 0C4.71 0 0 4.71 0 10.5S4.71 21 10.5 21 21 16.29 21 10.5 16.29 0 10.5 0z'/%3E%3C/svg%3E") no-repeat;
}

body:not(.elementor-editor-active) .bt-header-sidebar .elementor-widget-wrap > .elementor-element {
  width: auto;
  flex: auto;
}

body:not(.elementor-editor-active) .bt-header-sidebar .elementor-widget-wrap > .elementor-widget-nav-menu {
  width: 100%;
  flex: 1 1 100%;
}

body:not(.elementor-editor-active) .bt-header-sidebar .elementor-widget:not(:last-child) {
  margin-bottom: 0;
}

body:not(.elementor-editor-active) .bt-header-sidebar .elementor-nav-menu {
  border-top: 1px solid #FFFFFF33;
}

.footer-top {
  background: #000;
  font-size: 16px;
  color: #C8C8C8;
  border-top: 3px solid #F37725;
  padding: 48px 0 63px;
}

.footer-top .container {
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: flex-start;
}

.footer-top .container > * {
  flex: 1 1 25%;
  padding: 0 15px;
  display: block;
}

.footer-top a {
  color: #C8C8C8;
  text-decoration: none;
}

.footer-top a:hover {
  text-decoration: underline;
}

.footer-top .menu li {
  margin-bottom: 15px;
}

.footer-top p {
  margin-bottom: 15px;
}

.footer-bot {
  background: #2d2d2d;
  color: #C9C9C9;
  padding: 15px 0;
  font-size: 13px;
}

.footer-bot .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-bot a {
  color: #C9C9C9;
  text-decoration: none;
}

.footer-bot a:hover {
  text-decoration: underline;
}

.footer-logo img {
  width: 230px;
  height: auto;
}

.footer-social .menu {
  display: flex;
}

.footer-social .menu > li {
  margin-right: 28px;
}

.footer-social a {
  display: inline-block;
  font-size: 16px;
  width: 1em;
  height: 1em;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  color: transparent;
  overflow: hidden;
}

.footer-social a[href*="facebook.com"], .footer-social a[href*="fb.com"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.903' height='15.807'%3E%3Cpath d='M15.9 7.952c0-4.23-3.312-7.72-7.537-7.94S.48 2.916.04 7.123s2.488 8.023 6.667 8.684V10.25h-2.02v-2.3h2.02V6.2c-.088-.828.196-1.65.776-2.25s1.394-.907 2.224-.844c.596.008 1.19.06 1.78.155v1.956h-1a1.15 1.15 0 0 0-1.3 1.242v1.493H11.4l-.353 2.3H9.194V15.8c3.864-.614 6.707-3.946 6.706-7.858z' fill='%23acacac'/%3E%3C/svg%3E");
}

.footer-social a[href*="facebook.com"]:hover, .footer-social a[href*="fb.com"]:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.903' height='15.807'%3E%3Cpath d='M15.9 7.952c0-4.23-3.312-7.72-7.537-7.94S.48 2.916.04 7.123s2.488 8.023 6.667 8.684V10.25h-2.02v-2.3h2.02V6.2c-.088-.828.196-1.65.776-2.25s1.394-.907 2.224-.844c.596.008 1.19.06 1.78.155v1.956h-1a1.15 1.15 0 0 0-1.3 1.242v1.493H11.4l-.353 2.3H9.194V15.8c3.864-.614 6.707-3.946 6.706-7.858z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.footer-social a[href*="youtube.com"], .footer-social a[href*="youtu.be"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.51' height='12.312'%3E%3Cpath d='M17.145 1.926A2.2 2.2 0 0 0 15.597.368 52 52 0 0 0 8.755 0a52 52 0 0 0-6.841.368A2.2 2.2 0 0 0 .367 1.926C-.12 4.733-.12 7.603.367 10.4c.203.752.794 1.337 1.548 1.533a52 52 0 0 0 6.841.368 52 52 0 0 0 6.841-.368c.754-.196 1.345-.78 1.548-1.533.488-2.807.488-5.677 0-8.484zM6.967 8.772V3.565l4.577 2.6-4.577 2.6z' fill='%23acacac'/%3E%3C/svg%3E");
}

.footer-social a[href*="youtube.com"]:hover, .footer-social a[href*="youtu.be"]:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.51' height='12.312'%3E%3Cpath d='M17.145 1.926A2.2 2.2 0 0 0 15.597.368 52 52 0 0 0 8.755 0a52 52 0 0 0-6.841.368A2.2 2.2 0 0 0 .367 1.926C-.12 4.733-.12 7.603.367 10.4c.203.752.794 1.337 1.548 1.533a52 52 0 0 0 6.841.368 52 52 0 0 0 6.841-.368c.754-.196 1.345-.78 1.548-1.533.488-2.807.488-5.677 0-8.484zM6.967 8.772V3.565l4.577 2.6-4.577 2.6z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.footer-social a[href*="twitter.com"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.416' height='13.333'%3E%3Cpath d='M14.73 3.318l.01.437c.018 2.544-.985 5-2.784 6.79s-4.244 2.802-6.79 2.784A9.51 9.51 0 0 1 0 11.818a6.96 6.96 0 0 0 .812.042 6.74 6.74 0 0 0 4.177-1.437A3.37 3.37 0 0 1 1.843 8.09a4.24 4.24 0 0 0 .635.052 3.56 3.56 0 0 0 .885-.115c-1.57-.317-2.7-1.697-2.7-3.3v-.042a3.39 3.39 0 0 0 1.521.427 3.37 3.37 0 0 1-1.042-4.5c1.71 2.104 4.23 3.383 6.937 3.52A3.8 3.8 0 0 1 8 3.364 3.37 3.37 0 0 1 10.131.237a3.37 3.37 0 0 1 3.692.827C14.576.92 15.3.644 15.958.252c-.25.777-.777 1.437-1.48 1.854.668-.076 1.32-.252 1.937-.52a7.23 7.23 0 0 1-1.687 1.733z' fill='%23acacac'/%3E%3C/svg%3E");
}

.footer-social a[href*="twitter.com"]:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.416' height='13.333'%3E%3Cpath d='M14.73 3.318l.01.437c.018 2.544-.985 5-2.784 6.79s-4.244 2.802-6.79 2.784A9.51 9.51 0 0 1 0 11.818a6.96 6.96 0 0 0 .812.042 6.74 6.74 0 0 0 4.177-1.437A3.37 3.37 0 0 1 1.843 8.09a4.24 4.24 0 0 0 .635.052 3.56 3.56 0 0 0 .885-.115c-1.57-.317-2.7-1.697-2.7-3.3v-.042a3.39 3.39 0 0 0 1.521.427 3.37 3.37 0 0 1-1.042-4.5c1.71 2.104 4.23 3.383 6.937 3.52A3.8 3.8 0 0 1 8 3.364 3.37 3.37 0 0 1 10.131.237a3.37 3.37 0 0 1 3.692.827C14.576.92 15.3.644 15.958.252c-.25.777-.777 1.437-1.48 1.854.668-.076 1.32-.252 1.937-.52a7.23 7.23 0 0 1-1.687 1.733z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.bt-footer-bot p {
  margin: 0;
}

.elementor-location-footer {
  color: #c8c8c8;
}

.elementor-location-footer a {
  color: #c8c8c8;
}

.elementor-location-footer a:hover {
  color: #f37725;
}

.entry-header {
  text-align: center;
}

.bt-site {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
}

.admin-bar .bt-site {
  min-height: calc(100vh - 32px);
}

@media (max-width: 782px) {
  .admin-bar .bt-site {
    min-height: calc(100vh - 46px);
  }
}

.site-main {
  flex: 1 1 auto;
}

.entry-footer {
  height: auto;
}

.elementor-section.elementor-section-stretched {
  max-width: 100%;
}

.elementor-slides .swiper-slide-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.swiper-wrapper .swiper-slide {
  padding: 0;
}

.swiper-wrapper .swiper-slide-contents {
  margin: 0 auto;
  width: 100%;
}

.main-slider .swiper-wrapper .swiper-slide-contents > * {
  max-width: 535px;
}

.main-slider .swiper-container-horizontal > .swiper-pagination-bullets, .main-slider .swiper-pagination-custom, .main-slider .swiper-pagination-fraction {
  line-height: 0;
  bottom: 31px;
}

.main-slider .swiper-pagination-bullet {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.69);
  opacity: 1;
  box-sizing: border-box;
  transition: all 0.3s;
}

.main-slider .swiper-pagination-bullet-active {
  border: 1px solid rgba(112, 112, 112, 0.69);
  background-color: rgba(255, 255, 255, 0.7);
}

.main-slider .elementor-slides .swiper-slide-inner .elementor-slide-button {
  text-align: center;
  padding: 21px 25px;
  min-width: 308px;
}

@media (max-width: 767px) {
  .main-slider .elementor-slides .swiper-slide-inner .elementor-slide-button {
    min-width: unset;
    padding: 10px 25px;
  }
}

.main-slider .elementor-slide-heading {
  padding-bottom: 36px;
  position: relative;
}

.main-slider .elementor-slide-heading:before {
  content: '';
  width: 154px;
  height: 2px;
  background-color: #6a7480;
  position: absolute;
  top: 100%;
  left: 0;
}

@media (max-width: 767px) {
  .main-slider .elementor-slide-heading {
    padding-bottom: 20px;
  }
}

.main-banner .wp-caption {
  position: relative;
}

.main-banner .wp-caption a {
  display: block;
  position: relative;
}

.main-banner .wp-caption a:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
}

.main-banner .wp-caption a img {
  display: block;
  position: relative;
}

.main-banner .widget-image-caption.wp-caption-text {
  position: absolute;
  left: 52px;
  bottom: 21px;
  z-index: 2;
}

@media (max-width: 767px) {
  .main-banner .widget-image-caption.wp-caption-text {
    left: 30px;
  }
}

.main-heading .elementor-heading-title {
  position: relative;
}

.main-heading .elementor-heading-title:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  width: 154px;
  height: 2px;
  background-color: #f37725;
}

.site-main.page ul:not([class]), .site-main.page ol:not([class]) {
  margin: 0 0 1em;
  padding: 0 0 0 1.5em;
}

[dir="rtl"] .site-main.page ul:not([class]), [dir="rtl"] .site-main.page ol:not([class]) {
  padding: 0 1.5em 0 0;
}

.site-main.page ul:not([class]) {
  list-style: disc;
}

.site-main.page ol:not([class]) {
  list-style: decimal;
}

.wp-block-getwid-media-text-slider__slides-wrapper {
  overflow: hidden;
}

.wp-block-getwid-media-text-slider__slides-wrapper .slick-dotted.slick-slider {
  padding-bottom: 0;
}

.wp-block-getwid-media-text-slider__slides-wrapper .slick-dots {
  bottom: 40px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.wp-block-getwid-media-text-slider__slides-wrapper .slick-dots li button {
  padding: 0;
  width: 18px;
  height: 18px;
  color: transparent;
}

.wp-block-getwid-media-text-slider__slides-wrapper .slick-dots li button:before {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid white;
  background: none;
  content: '';
  opacity: 0.69;
}

.wp-block-getwid-media-text-slider__slides-wrapper .slick-dots li button:hover:before {
  opacity: 1;
}

.wp-block-getwid-media-text-slider__slides-wrapper .slick-dots li.slick-active button:before {
  border: 1px solid #707070;
  background-color: white;
}

.wp-block-getwid-media-text-slider-slide__content {
  padding: 0;
}

.wp-block-getwid-media-text-slider-slide-content__content {
  padding: 112px 15px;
  max-width: 1586px;
  margin: 0 auto;
}

.wp-block-getwid-media-text-slider-slide-content__content-wrapper {
  max-width: 528px;
  color: #ffffff;
  font-size: 20px;
  line-height: 1.2;
  font-style: italic;
}

.wp-block-getwid-media-text-slider-slide-content__content-wrapper h2 {
  font-size: 45px;
  font-weight: 500;
  line-height: 1.1778;
  padding-bottom: 36px;
  margin: 0 0 18px;
  font-style: normal;
  position: relative;
}

.wp-block-getwid-media-text-slider-slide-content__content-wrapper h2:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 154px;
  height: 2px;
  background-color: #6a7480;
}

.wp-block-getwid-media-text-slider-slide-content__content-wrapper .wp-block-buttons {
  margin-top: 57px;
}

.wp-block-getwid-media-text-slider-slide-content__content-wrapper .wp-block-button__link {
  background-color: #414141;
  border-radius: 0;
  color: #f37725;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  font-style: normal;
}

.index_about {
  font-size: 18px;
  font-weight: 300;
  margin: 0 auto;
  max-width: 794px;
  line-height: 1.1667;
  padding-top: 50px;
}

body.bt-menu-open {
  overflow: hidden;
}

.bt-wi-slider {
  max-width: 1700px;
  margin: 0 auto;
}

.bt-main-widget {
  margin-bottom: 45px;
}

.bt-main__title {
  color: #000000;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 45px;
  position: relative;
}

.bt-main__title::before {
  content: '';
  position: absolute;
  width: 155px;
  left: 0;
  right: 0;
  bottom: -15px;
  margin: 0 auto;
  height: 2px;
  background-color: #f37725;
}

.bt-wi-lots_more {
  text-align: center;
}

.bt-wi-lots_more button {
  display: block;
  color: #1f3042;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: transparent;
}

.bt-page-link_wrp {
  padding: 0 15px;
}

.bt-page-link_wrp .bt-page-link {
  position: relative;
  display: block;
}

.bt-page-link_wrp .bt-page-link::before {
  content: '';
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.bt-page-link_wrp .bt-page-link .wp-block-media-text__media {
  display: flex;
  width: 100%;
}

.bt-page-link_wrp .bt-page-link .wp-block-media-text__media a {
  display: inherit;
}

.bt-page-link_wrp .bt-page-link .wp-block-media-text__media img {
  max-width: 100%;
}

.bt-page-link_wrp .bt-page-link .wp-block-media-text__content {
  position: absolute;
  left: 30px;
  bottom: 20px;
  grid-column: initial;
  grid-row: initial;
  padding: 0;
  pointer-events: none;
}

.bt-page-link_wrp .bt-page-link .wp-block-media-text__content p {
  margin-bottom: 0;
  color: #fff;
  font-size: 38px;
  font-weight: 400;
  pointer-events: none;
}

.bt-bg-head {
  min-height: 215px;
  align-content: center;
  position: relative;
  height: auto;
  display: flex;
  justify-content: center;
  padding: 30px;
  margin-bottom: 45px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bt-bg-head::before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
}

.bt-bg-head h1 {
  margin-bottom: 0;
  color: #fff;
  vertical-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 2;
}

.wpcf7-form label {
  width: 100%;
  color: #000;
  font-size: 18px;
  font-weight: 300;
}

.wpcf7-form p {
  position: relative;
}

.wpcf7-form p:last-of-type {
  margin-bottom: 0;
}

.wpcf7-form p .ajax-loader {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.wpcf7-form .wpcf7-form-control {
  position: relative;
  width: 100%;
  padding: 15px 20px;
  border: 1px solid #707070;
  background-color: #ffffff;
  color: #000;
  font-size: 20px;
  font-weight: 300;
}

.wpcf7-form .wpcf7-form-control.wpcf7-submit {
  height: 72px;
  color: #fff;
  background-color: #000000;
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
}

.bt-wrp-site-head-mobile {
  display: none;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
  height: 100%;
}

.bt-wrp-site-head-mobile .bt-spoiler-trigger {
  position: relative;
  display: flex;
  height: auto;
  width: auto;
}

@media (max-width: 976px) {
  .bt-m-hidden {
    display: none !important;
  }
  .wp-block-columns.bt-page-link_wrp {
    flex-wrap: wrap;
  }
  .wp-block-columns.bt-page-link_wrp .wp-block-column {
    flex-basis: 100% !important;
    margin: 0 0 30px 0;
    flex-grow: 1 !important;
  }
  .wp-block-columns.bt-page-link_wrp .wp-block-column:last-child {
    margin-bottom: 0;
  }
  .footer-top .container {
    flex-wrap: wrap;
  }
  .footer-top .container > * {
    flex: 1 1 50%;
    margin-bottom: 15px;
  }
  .bt-wrp-site-head-mobile {
    display: flex;
  }
  .site-header .bt-spoiler-block {
    display: none;
    z-index: 9;
    background-color: #fff;
    justify-content: flex-start;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    width: 80vw;
    padding: 15% 25px 25px;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    transition: mmslideInLeft 0.25s ease-out;
    flex-wrap: wrap;
  }
  .site-header .bt-spoiler-block.active {
    display: flex;
  }
  .site-header .bt-spoiler-block .bt-spoiler-block__item {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
  .site-header .bt-spoiler-block .bt-spoiler-block__item:last-child {
    margin-top: 0;
  }
  .site-header .bt-spoiler-block .bt-spoiler-block__item.user_nav {
    order: 1;
  }
  .site-header .bt-spoiler-block .bt-spoiler-block__item.user_nav .menu {
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  .site-header .bt-spoiler-block .bt-spoiler-block__item.main-navigation {
    order: 2;
  }
  .site-header .bt-spoiler-block .bt-spoiler-block__item.main-navigation .menu {
    justify-content: center;
    flex-wrap: wrap;
  }
  .site-header .main-navigation .menu > li {
    margin: 0 15px 15px 0;
    display: block;
    flex-basis: 100%;
    text-align: right;
  }
  .site-header .main-navigation .menu .sub-menu {
    display: block;
  }
  .site-header .main-navigation .menu .sub-menu > .menu-item {
    margin: 10px 15px 0 0;
  }
}

article.page {
  margin-bottom: 45px;
}

@media (max-width: 568px) {
  .footer-top .container > * {
    flex: 1 1 100%;
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .bt-page-link_wrp .bt-page-link .wp-block-media-text__content p {
    font-size: 22px;
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(-15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideInLeft {
  from {
    transform: translateX(-15%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(10%);
  }
}

@keyframes mmslideOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(10%);
  }
}

.bt-icon-menu {
  display: inline-block;
  margin: 0;
  cursor: pointer;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
  background-color: #fff;
}

.bt-icon-menu:before {
  content: '';
  background-image: url("../svg/menu.svg");
  height: 100%;
  width: 100%;
  display: inline-block;
  background-position: center;
  background-size: cover;
}

.bt-navbar {
  background: #09c;
  width: 100%;
  height: 75px;
  position: absolute;
  z-index: 99999;
  box-shadow: 0 0 10px #000;
}

.bt-search {
  position: absolute;
  background: #007399;
  width: 100%;
  left: 0;
  top: -100%;
  z-index: 999;
  transition-duration: 1s;
  box-shadow: 0 0 10px #000;
  padding: 15px;
  line-height: 75px;
}

.bt-search h1 {
  float: left;
  margin: 0;
  color: #fff;
  padding-right: 25px;
  font-weight: normal;
}

.bt-search input[type='search'] {
  padding: 10px;
  width: 250px;
}

.bt-down {
  top: 74px;
}

.menu .sub-menu {
  display: none;
}

body.mm-wrapper_opened {
  overflow-x: hidden;
}

.bt-site-header {
  min-height: 90px;
}

.bt-site-header.bt-fixed {
  min-height: 60px;
}

.bt-site-header .custom-logo-link img {
  padding-left: 10px;
}

.bt-wrp-site-head-mobile {
  min-height: 80px;
  align-items: center;
}

.st-menu .user_nav .menu a:hover .icon-user {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cpath fill='%23fff' d='M17.437 17.415c-.767-.982-3.152-2.058-4.623-2.654-.164-.067-.342-.61-.369-1.392a3.17 3.17 0 0 0 .535-.687c.058-.096.116-.187.174-.277.185-.29.376-.588.541-.99a.951.951 0 0 0 .41-.392c.317-.58.57-1.649.408-2.105a.622.622 0 0 0-.332-.364c-.013-1.894-.863-3.347-2.297-3.915a10.404 10.404 0 0 0-1.41-.492c-.254-.07-.386-.186-.397-.191a.35.35 0 0 0-.414-.286c-.28.056-.521.421-.615.653a1.098 1.098 0 0 0-.068.24c-.409.064-.948.27-1.104.834a.375.375 0 0 0-.019.155c-.513.582-.998 1.518-1.008 2.983a.634.634 0 0 0-.37.38c-.164.463.084 1.511.407 2.104.101.185.265.33.446.406.162.398.35.693.532.98.054.086.11.173.166.264.19.313.384.55.599.731-.046.784-.244 1.31-.38 1.35-.476.142-3.786 1.412-4.687 2.664A9.769 9.769 0 0 1 .7 10.5C.7 5.096 5.096.7 10.5.7c5.404 0 9.8 4.396 9.8 9.8a9.769 9.769 0 0 1-2.863 6.915zm-13.356.48zm4.37-2.475c.724-.216.865-1.457.885-2.177a.348.348 0 0 0-.151-.297c-.197-.136-.374-.34-.558-.641-.058-.096-.116-.187-.172-.276-.205-.322-.382-.6-.531-1.015a.34.34 0 0 0-.312-.23.22.22 0 0 1-.112-.1c-.294-.541-.402-1.286-.368-1.504l.063-.002a.35.35 0 0 0 .359-.363C7.49 7.126 8.11 6.22 8.64 5.758a.351.351 0 0 0 .097-.389c.24-.134.613-.126.616-.127l.02-.35.295-.183c-.007-.034.013-.095.044-.16.168.126.378.218.575.272.608.169 1.05.343 1.326.464 1.249.495 1.928 1.781 1.863 3.531a.351.351 0 0 0 .343.363c.017 0 .03.001.04.003.032.216-.08.975-.369 1.505a.239.239 0 0 1-.11.103.35.35 0 0 0-.28.226c-.153.412-.33.69-.537 1.012l-.182.29c-.174.287-.34.488-.504.616a.35.35 0 0 0-.136.279c.005.7.116 1.915.81 2.196 2.279.924 4.016 1.914 4.375 2.48A9.757 9.757 0 0 1 10.5 20.3a9.757 9.757 0 0 1-6.419-2.404c.4-.798 3.205-2.126 4.37-2.476zM10.5 0C4.71 0 0 4.71 0 10.5S4.71 21 10.5 21 21 16.29 21 10.5 16.29 0 10.5 0z'/%3E%3C/svg%3E");
}

.st-menu .user_nav .menu a:hover .icon-search {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cpath fill='%23fff' d='M19.057 20.217l-5.07-5.07 1.259-1.259 5.07 5.07a1.409 1.409 0 0 1-1.26 1.259zM8.009 15.305c-4.023 0-7.296-3.273-7.296-7.297C.713 3.985 3.986.712 8.009.712c4.024 0 7.297 3.273 7.297 7.296 0 4.024-3.273 7.297-7.297 7.297zm7.22-2.423l-.922.93-.396-.4a7.98 7.98 0 0 0 2.104-5.404C16.015 3.592 12.424 0 8.008 0 3.592 0 0 3.592 0 8.008c0 4.417 3.593 8.009 8.009 8.009a7.98 7.98 0 0 0 5.403-2.103l.4.4-.832.865L18.77 21h.147C20.084 21 21 19.985 21 18.818v-.148z'/%3E%3C/svg%3E");
}

.st-menu .user_nav .menu .bp-login-link:hover span {
  color: #fff;
}

.st-menu .user_nav .menu li.wrpLoginLink::after {
  display: none;
}

.bt-site-header > .container {
  position: relative;
}

.bt-site-header .menu .menu-item a + .sub-menu {
  position: absolute;
  display: none;
  width: 100%;
  height: auto;
  left: 0;
  top: 100%;
}

.bt-site-header .menu .menu-item a.bt-active-menu + .sub-menu {
  display: flex;
}

.bt-site-header .menu .menu-item a + .sub-menu {
  background-color: #efefef;
  border: 0;
  margin: 0;
  width: 100%;
  border-radius: 0;
  top: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  max-width: none;
  max-height: none;
  border-bottom: 3px solid #efefef;
  padding: 6.25rem 12.5rem;
  z-index: 9;
}

.main-navigation .menu .current-menu-item a {
  color: initial;
}

.main-navigation .menu .current-menu-item a:hover {
  color: #F37725;
}

.main-navigation .menu .current-menu-item > a {
  color: #F37725;
}

.st-menu .menu-item-has-children > a {
  position: relative;
}

.st-menu .menu-item-has-children > a::after {
  content: '';
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.st-content .menu-item-has-children > a::after {
  content: '';
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.st-menu .menu-item-has-children > a.bt-active-menu::after, .st-content .menu-item-has-children > a.bt-active-menu::after {
  border-bottom: .3em solid;
  border-top: 0;
}

.st-menu a.bt-active-menu + .sub-menu {
  display: flex;
}

.st-menu .sub-menu {
  flex-wrap: wrap;
  padding-left: 15px;
  margin-bottom: 10px;
}

.st-menu .sub-menu .menu-item {
  flex-basis: 100%;
  width: 100%;
  border-top: none;
}

.st-menu .sub-menu .menu-item a {
  padding: 7px 25px;
}

.st-content .sub-menu .menu-item {
  margin: 5px 0;
}

@media (max-width: 991px) {
  .hamburger {
    display: block;
  }
}

.over {
  overflow: hidden;
}

.clearfix:before, .clearfix:after, .cf:before, .cf:after {
  content: " ";
  display: table;
}

.clearfix:after, .cf:after {
  clear: both;
}

.clearfix, .cf {
  zoom: 1;
}

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a, a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr, img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: .5cm;
  }
  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }
  h2, h3 {
    page-break-after: avoid;
  }
}
