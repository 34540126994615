html, body, .st-container, .st-pusher {
	height: 100%; }
html.admin-bar {
	height: calc(100% - 32px);
	@media (max-width: 782px) {
		height: calc(100% - 46px); } }


.st-content {
	//height: 100%
	position: relative;
	//overflow-y: scroll
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s; }

.st-content-inner {
	position: relative;
	min-height: 100%;
	display: flex;
	flex-direction: column; }

.st-container {
	position: relative;
 }	//overflow: hidden


.st-menu {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 110;
	visibility: hidden;
	width: 0;
	height: 100%;
	background: #F37725;
	color: #ffffff;
	transition: all 0.5s;
	//transform: translate3d(100%, 0, 0)
	box-sizing: border-box;
	padding: 0;
	overflow-x: hidden;
	.menu {
		height: 100%;
		overflow: auto;
		a {
			display: block;
			padding: 10px 25px;
			text-decoration: none;
			color: #ffffff; }
		li+li {
			border-top: 1px solid rgba(#fff,0.2); } }
	.st-menu-inner {
		height: 100%;
		padding: 15px 0;
		overflow: auto; }
	.user_nav {
		margin-top: 30px;
		.menu {
			li+li {
				border: none; }
			>li {
				margin: 0; }
			.icon-search {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cpath fill='%23fff' d='M19.057 20.217l-5.07-5.07 1.259-1.259 5.07 5.07a1.409 1.409 0 0 1-1.26 1.259zM8.009 15.305c-4.023 0-7.296-3.273-7.296-7.297C.713 3.985 3.986.712 8.009.712c4.024 0 7.297 3.273 7.297 7.296 0 4.024-3.273 7.297-7.297 7.297zm7.22-2.423l-.922.93-.396-.4a7.98 7.98 0 0 0 2.104-5.404C16.015 3.592 12.424 0 8.008 0 3.592 0 0 3.592 0 8.008c0 4.417 3.593 8.009 8.009 8.009a7.98 7.98 0 0 0 5.403-2.103l.4.4-.832.865L18.77 21h.147C20.084 21 21 19.985 21 18.818v-.148z'/%3E%3C/svg%3E"); }
			.icon-user {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cpath fill='%23fff' d='M17.437 17.415c-.767-.982-3.152-2.058-4.623-2.654-.164-.067-.342-.61-.369-1.392a3.17 3.17 0 0 0 .535-.687c.058-.096.116-.187.174-.277.185-.29.376-.588.541-.99a.951.951 0 0 0 .41-.392c.317-.58.57-1.649.408-2.105a.622.622 0 0 0-.332-.364c-.013-1.894-.863-3.347-2.297-3.915a10.404 10.404 0 0 0-1.41-.492c-.254-.07-.386-.186-.397-.191a.35.35 0 0 0-.414-.286c-.28.056-.521.421-.615.653a1.098 1.098 0 0 0-.068.24c-.409.064-.948.27-1.104.834a.375.375 0 0 0-.019.155c-.513.582-.998 1.518-1.008 2.983a.634.634 0 0 0-.37.38c-.164.463.084 1.511.407 2.104.101.185.265.33.446.406.162.398.35.693.532.98.054.086.11.173.166.264.19.313.384.55.599.731-.046.784-.244 1.31-.38 1.35-.476.142-3.786 1.412-4.687 2.664A9.769 9.769 0 0 1 .7 10.5C.7 5.096 5.096.7 10.5.7c5.404 0 9.8 4.396 9.8 9.8a9.769 9.769 0 0 1-2.863 6.915zm-13.356.48zm4.37-2.475c.724-.216.865-1.457.885-2.177a.348.348 0 0 0-.151-.297c-.197-.136-.374-.34-.558-.641-.058-.096-.116-.187-.172-.276-.205-.322-.382-.6-.531-1.015a.34.34 0 0 0-.312-.23.22.22 0 0 1-.112-.1c-.294-.541-.402-1.286-.368-1.504l.063-.002a.35.35 0 0 0 .359-.363C7.49 7.126 8.11 6.22 8.64 5.758a.351.351 0 0 0 .097-.389c.24-.134.613-.126.616-.127l.02-.35.295-.183c-.007-.034.013-.095.044-.16.168.126.378.218.575.272.608.169 1.05.343 1.326.464 1.249.495 1.928 1.781 1.863 3.531a.351.351 0 0 0 .343.363c.017 0 .03.001.04.003.032.216-.08.975-.369 1.505a.239.239 0 0 1-.11.103.35.35 0 0 0-.28.226c-.153.412-.33.69-.537 1.012l-.182.29c-.174.287-.34.488-.504.616a.35.35 0 0 0-.136.279c.005.7.116 1.915.81 2.196 2.279.924 4.016 1.914 4.375 2.48A9.757 9.757 0 0 1 10.5 20.3a9.757 9.757 0 0 1-6.419-2.404c.4-.798 3.205-2.126 4.37-2.476zM10.5 0C4.71 0 0 4.71 0 10.5S4.71 21 10.5 21 21 16.29 21 10.5 16.29 0 10.5 0z'/%3E%3C/svg%3E"); }
			.bp-login-link {
				span {
					color: #ffffff; } } } } }


.st-pusher {
	position: relative;
	left: 0;
	z-index: 99;
	height: 100%;
	//perspective: 1000px
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	&::after {
		position: fixed;
		top: 0;
		right: 0;
		width: 0;
		height: 0;
		background: rgba(0, 0, 0, 0.2);
		content: '';
		opacity: 0;
		-webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
		transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
		z-index: 100; } }

.st-menu-open {
	overflow: hidden;
	.st-menu {
		visibility: visible;
		transition: width 0.5s;
		width: 300px; }
	.st-pusher {
		.st-content {
			transform: translate3d(-300px, 0, 0); } } }

.st-menu-open .st-pusher::after {
	width: 100%;
	height: 100%;
	opacity: 1;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s; }
