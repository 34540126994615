.wp-block-getwid-media-text-slider__slides-wrapper {
	overflow: hidden;
	.slick-dotted.slick-slider {
		padding-bottom: 0; }
	.slick-dots {
		bottom: 40px;
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			button {
				padding: 0;
				width: 18px;
				height: 18px;
				color: transparent;
				&:before {
					width: 18px;
					height: 18px;
					border-radius: 50%;
					box-sizing: border-box;
					border: 2px solid rgba(255, 255, 255, 1);
					background: none;
					content: '';
					opacity: 0.69; }
				&:hover {
					&:before {
						opacity: 1; } } }
			&.slick-active {
				button {
					&:before {
						border: 1px solid rgba(112, 112, 112, 1);
						background-color: rgba(255, 255, 255, 1); } } } } } }
.wp-block-getwid-media-text-slider-slide__content {
	padding: 0; }
.wp-block-getwid-media-text-slider-slide-content__content {
	padding: 112px 15px;
	max-width: 1556px + 30;
	margin: 0 auto; }
.wp-block-getwid-media-text-slider-slide-content__content-wrapper {
	max-width: 528px;
	color: #ffffff;
	font-size: 20px;
	line-height: 1.2;
	font-style: italic;
	h2 {
		font-size: 45px;
		font-weight: 500;
		line-height: 1.1778;
		padding-bottom: 36px;
		margin: 0 0 18px;
		font-style: normal;
		position: relative;
		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 154px;
			height: 2px;
			background-color: #6a7480; } }
	.wp-block-buttons {
		margin-top: 57px; }
	.wp-block-button__link {
		background-color: #414141;
		border-radius: 0;
		color: #f37725;
		font-size: 20px;
		font-weight: 400;
		text-transform: uppercase;
		font-style: normal; } }


.index_about {
	font-size: 18px;
	font-weight: 300;
	margin: 0 auto;
	max-width: 794px;
	line-height: 1.1667;
	padding-top: 50px;
	h2 {} }




