.footer-top {
	background: #000;
	font-size: 16px;
	color: #C8C8C8;
	border-top: 3px solid #F37725;
	padding: 48px 0 63px;
	.container {
		display: flex;
		flex-direction: row;
		padding: 0;
		align-items: flex-start;
		>* {
			flex: 1 1 25%;
			padding: 0 15px;
			display: block; } }
	a {
		color: #C8C8C8;
		text-decoration: none;
		&:hover {
			text-decoration: underline; } }
	.menu {
		li {
			margin-bottom: 15px; } }
	p {
		margin-bottom: 15px; } }
.footer-bot {
	background: #2d2d2d;
	color: #C9C9C9;
	padding: 15px 0;
	font-size: 13px;
	.container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center; }
	a {
		color: #C9C9C9;
		text-decoration: none;
		&:hover {
			text-decoration: underline; } } }
.footer-logo {
	img {
		width: 230px;
		height: auto; } }

.footer-social {
	.menu {
		display: flex;
		>li {
			margin-right: 28px; } }
	a {
		display: inline-block;
		font-size: 16px;
		width: 1em;
		height: 1em;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 50%;
		color: transparent;
		overflow: hidden; }
	a[href*="facebook.com"], a[href*="fb.com"] {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.903' height='15.807'%3E%3Cpath d='M15.9 7.952c0-4.23-3.312-7.72-7.537-7.94S.48 2.916.04 7.123s2.488 8.023 6.667 8.684V10.25h-2.02v-2.3h2.02V6.2c-.088-.828.196-1.65.776-2.25s1.394-.907 2.224-.844c.596.008 1.19.06 1.78.155v1.956h-1a1.15 1.15 0 0 0-1.3 1.242v1.493H11.4l-.353 2.3H9.194V15.8c3.864-.614 6.707-3.946 6.706-7.858z' fill='%23acacac'/%3E%3C/svg%3E");
		&:hover {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.903' height='15.807'%3E%3Cpath d='M15.9 7.952c0-4.23-3.312-7.72-7.537-7.94S.48 2.916.04 7.123s2.488 8.023 6.667 8.684V10.25h-2.02v-2.3h2.02V6.2c-.088-.828.196-1.65.776-2.25s1.394-.907 2.224-.844c.596.008 1.19.06 1.78.155v1.956h-1a1.15 1.15 0 0 0-1.3 1.242v1.493H11.4l-.353 2.3H9.194V15.8c3.864-.614 6.707-3.946 6.706-7.858z' fill='%23ffffff'/%3E%3C/svg%3E"); } }
	a[href*="youtube.com"], a[href*="youtu.be"] {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.51' height='12.312'%3E%3Cpath d='M17.145 1.926A2.2 2.2 0 0 0 15.597.368 52 52 0 0 0 8.755 0a52 52 0 0 0-6.841.368A2.2 2.2 0 0 0 .367 1.926C-.12 4.733-.12 7.603.367 10.4c.203.752.794 1.337 1.548 1.533a52 52 0 0 0 6.841.368 52 52 0 0 0 6.841-.368c.754-.196 1.345-.78 1.548-1.533.488-2.807.488-5.677 0-8.484zM6.967 8.772V3.565l4.577 2.6-4.577 2.6z' fill='%23acacac'/%3E%3C/svg%3E");
		&:hover {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.51' height='12.312'%3E%3Cpath d='M17.145 1.926A2.2 2.2 0 0 0 15.597.368 52 52 0 0 0 8.755 0a52 52 0 0 0-6.841.368A2.2 2.2 0 0 0 .367 1.926C-.12 4.733-.12 7.603.367 10.4c.203.752.794 1.337 1.548 1.533a52 52 0 0 0 6.841.368 52 52 0 0 0 6.841-.368c.754-.196 1.345-.78 1.548-1.533.488-2.807.488-5.677 0-8.484zM6.967 8.772V3.565l4.577 2.6-4.577 2.6z' fill='%23ffffff'/%3E%3C/svg%3E"); } }
	a[href*="twitter.com"] {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.416' height='13.333'%3E%3Cpath d='M14.73 3.318l.01.437c.018 2.544-.985 5-2.784 6.79s-4.244 2.802-6.79 2.784A9.51 9.51 0 0 1 0 11.818a6.96 6.96 0 0 0 .812.042 6.74 6.74 0 0 0 4.177-1.437A3.37 3.37 0 0 1 1.843 8.09a4.24 4.24 0 0 0 .635.052 3.56 3.56 0 0 0 .885-.115c-1.57-.317-2.7-1.697-2.7-3.3v-.042a3.39 3.39 0 0 0 1.521.427 3.37 3.37 0 0 1-1.042-4.5c1.71 2.104 4.23 3.383 6.937 3.52A3.8 3.8 0 0 1 8 3.364 3.37 3.37 0 0 1 10.131.237a3.37 3.37 0 0 1 3.692.827C14.576.92 15.3.644 15.958.252c-.25.777-.777 1.437-1.48 1.854.668-.076 1.32-.252 1.937-.52a7.23 7.23 0 0 1-1.687 1.733z' fill='%23acacac'/%3E%3C/svg%3E");
		&:hover {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.416' height='13.333'%3E%3Cpath d='M14.73 3.318l.01.437c.018 2.544-.985 5-2.784 6.79s-4.244 2.802-6.79 2.784A9.51 9.51 0 0 1 0 11.818a6.96 6.96 0 0 0 .812.042 6.74 6.74 0 0 0 4.177-1.437A3.37 3.37 0 0 1 1.843 8.09a4.24 4.24 0 0 0 .635.052 3.56 3.56 0 0 0 .885-.115c-1.57-.317-2.7-1.697-2.7-3.3v-.042a3.39 3.39 0 0 0 1.521.427 3.37 3.37 0 0 1-1.042-4.5c1.71 2.104 4.23 3.383 6.937 3.52A3.8 3.8 0 0 1 8 3.364 3.37 3.37 0 0 1 10.131.237a3.37 3.37 0 0 1 3.692.827C14.576.92 15.3.644 15.958.252c-.25.777-.777 1.437-1.48 1.854.668-.076 1.32-.252 1.937-.52a7.23 7.23 0 0 1-1.687 1.733z' fill='%23ffffff'/%3E%3C/svg%3E"); } } }


.bt-footer-top {}

.bt-footer-bot {
	p {
		margin: 0; } }
.elementor-location-footer {
	color: #c8c8c8;
	a {
		color: #c8c8c8;
		&:hover {
			color: #f37725; } } }









