@charset "UTF-8";

@import "var";
//@import "icons"
@import "hamburgers";

body {
	font: #{$base-fz}/#{$base-lh} $font;
	color: $color; }

select, input, textarea, button {
	font: 99% $font; }
pre, code, kbd, samp {
	font-family: monospace, sans-serif; }
a {
	text-decoration: underline;
	color: $link;
	&:visited {
		color: $link; } }
a:hover {
	text-decoration: none; }
h1,h2,h3,h4,h5,h6,b,strong {
	font-weight: bold; }
em,i {
	font-style: italic; }
h1,h2,h3,h4,h5,h6,p,blockquote,ul,ol,dl,address,table {
	margin-bottom: 1em; }
.menu {
	margin: 0;
	list-style: none; }
h6 {
	font-size: 100%; }
h5 {
	font-size: 115%; }
h4 {
	font-size: 125%; }
h3 {
	font-size: 150%; }
h2 {
	font-size: 175%; }
h1 {
	font-size: 200%; }
sub, sup {
	font-size: 75%;
	line-height: 0;
	position: relative; }
sup {
	top: -0.5em; }
sub {
	bottom: -0.25em; }



html {
	//height: 100%
	min-width: 320px; }

body, html {
	height: auto; }

.wrapper {
	min-height: 100%;
	height: auto !important;
	height: 100%;
	display: flex;
	flex-direction: column; }


.container {
	max-width: $w + 30;
	margin: 0 auto;
	position: relative;
	padding-left: 15px;
	padding-right: 15px; }

.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;

	&:focus {
		background-color: #f1f1f1;
		border-radius: 3px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
		clip: auto !important;
		clip-path: none;
		color: #21759b;
		display: block;
		font-size: 0.875rem;
		font-weight: 700;
		height: auto;
		right: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; } }


.bt-contact-us {
	height: 100%;
	font-size: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	flex-wrap: wrap;
	background-color: #f1f1f1;
	p {
		margin-bottom: 20px;
		flex-basis: 100%;
		text-align: center; } }

.wpcf7-form .wpcf7-form-control.wpcf7-textarea {
	height: 135px; }

.bt-site.bt-header-fixed {
	padding-top: 90px; }

.bt-site-header {
	transition: all .4s ease;
	position: static;
	top: -60px;
	&.bt-fixed {
		position: fixed;
		z-index: 99;
		width: 100%;
		left: 0;
		right: 0;
		top: 0;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		height: auto; }

	img {
		transition: all .4s ease; }

	&.bt-fixed {
		img {
			height: 100%;
			width: auto;
			max-height: 45px;
			padding-left: 10px; }

		.bt-spoiler-block, .site-branding {
			height: 100%; } } }



.wrp-navigation {
	flex-basis: 100%; }

.bt-menu-overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 8;
	display: none;
	width: 100vw; }

.bt-menu__close {
	position: absolute;
	left: 25px;
	color: #fff;
	cursor: pointer;
	font-size: 48px;
	line-height: 26px;
	top: 46px; }

.bt-menu-open .bt-menu-overlay {
	display: block; }

@media (max-width: 600px) {
	.bt-contact-us {
		text-align: center; } }


@media (max-width: 480px) {
	.footer-bot .container {
		flex-wrap: wrap;
		justify-content: center;

		.footer-bot__item {
			text-align: center;
			flex-basis: 100%;
			width: 100%;
			margin-bottom: 15px;

			&:last-of-type {
				margin-bottom: 0; }

			.menu {
				justify-content: center;

				.menu-item:last-of-type {
					margin-right: 0; } } } } }


//@import "includes/mmenu"
@import "includes/sidebar_transition";


//Header
@import "includes/header";

//Footer
@import "includes/footer";

@import "page/content";
@import "page/index";


@import "_plugin";
@import "_burger";
@import "_menu";


@import "responsive/1200";
@import "responsive/992";
@import "responsive/768";



%cf {
	zoom: 1;
	&:before,&:after {
		content: " ";
		display: table; }
	&:after {
		clear: both; } }
.over {
	overflow: hidden; }
.clearfix:before,.clearfix:after,.cf:before,.cf:after {
	content: " ";
	display: table; }
.clearfix:after,.cf:after {
	clear: both; }
.clearfix,.cf {
	zoom: 1; }


@media print {
	* {
		background: transparent!important;
		color: #000!important;
		box-shadow: none!important;
		text-shadow: none!important; }
	a,a:visited {
		text-decoration: underline; }
	a[href]:after {
		content: " (" attr(href) ")"; }
	abbr[title]:after {
		content: " (" attr(title) ")"; }
	pre,blockquote {
		border: 1px solid #999;
		page-break-inside: avoid; }
	thead {
		display: table-header-group; }
	tr,img {
		page-break-inside: avoid; }
	img {
		max-width: 100%!important; }
	@page {
		margin: .5cm; }
	p,h2,h3 {
		orphans: 3;
		widows: 3; }
	h2,h3 {
		page-break-after: avoid; } }
