body {
    &.bt-menu-open {
        overflow: hidden; } }

.bt-wi-slider {
    max-width: 1700px;
    margin: 0 auto; }

.bt-main-widget {
    margin-bottom: 45px; }

.bt-main__title {
    color: #000000;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 45px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 155px;
        left: 0;
        right: 0;
        bottom: -15px;
        margin: 0 auto;
        height: 2px;
        background-color: #f37725; } }


.bt-wi-lots_more {
    text-align: center;

    button {
        display: block;
        color: #1f3042;
        margin: 0 auto;
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        background-color: transparent; } }

.bt-page-link_wrp {
    padding: 0 15px;

    .bt-page-link {
        position: relative;
        display: block;

        &::before {
            content: '';
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3); }

        .wp-block-media-text__media {
            display: flex;
            width: 100%;

            a {
                display: inherit; }

            img {
                max-width: 100%; } }

        .wp-block-media-text__content {
            position: absolute;
            left: 30px;
            bottom: 20px;
            grid-column: initial;
            grid-row: initial;
            padding: 0;
            pointer-events: none;

            p {
                margin-bottom: 0;
                color: #fff;
                font-size: 38px;
                font-weight: 400;
                pointer-events: none; } } } }

.bt-bg-head {
    min-height: 215px;
    align-content: center;
    position: relative;
    height: auto;
    display: flex;
    justify-content: center;
    padding: 30px;
    margin-bottom: 45px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        pointer-events: none; }

    h1 {
        margin-bottom: 0;
        color: #fff;
        vertical-align: center;
        display: flex;
        align-content: center;
        align-items: center;
        z-index: 2; } }



.wpcf7-form {
    label {
        width: 100%;
        color: #000;
        font-size: 18px;
        font-weight: 300; }

    p {
        position: relative;

        &:last-of-type {
            margin-bottom: 0; }

        .ajax-loader {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%); } }

    .wpcf7-form-control {
        position: relative;
        width: 100%;
        padding: 15px 20px;
        border: 1px solid #707070;
        background-color: #ffffff;
        color: #000;
        font-size: 20px;
        font-weight: 300;

        &.wpcf7-submit {
            height: 72px;
            color: #fff;
            background-color: #000000;
            width: 100%;
            font-size: 18px;
            font-weight: 300;
            text-transform: uppercase; } } }

.bt-wrp-site-head-mobile {
    display: none;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    height: 100%;
    .bt-spoiler-trigger {
        position: relative;
        display: flex;
        height: auto;
        width: auto; } }

@media (max-width: 976px) {
    .bt-m-hidden {
        display: none !important; }

    .wp-block-columns {
        &.bt-page-link_wrp {
            flex-wrap: wrap;

            .wp-block-column {
                flex-basis: 100% !important;
                margin: 0 0 30px 0;
                flex-grow: 1 !important;

                &:last-child {
                    margin-bottom: 0; } } } }

    .footer-top {
        .container {
            flex-wrap: wrap;

            > * {
                flex: 1 1 50%;
                margin-bottom: 15px; } } }

    .bt-wrp-site-head-mobile {
        display: flex; }

    //.site-header .bt-spoiler-block.active {
    //
    //}
    //
    //.site-header {
    //    .bt-spoiler-trigger {
    //    }
    //}

    .site-header {
        .bt-spoiler-block {
            display: none;
            z-index: 9;
            background-color: #fff;
            justify-content: flex-start;
            height: 100vh;
            position: fixed;
            top: 0;
            right: 0;
            width: 80vw;
            padding: 15% 25px 25px;
            align-items: flex-start;
            align-content: flex-start;
            flex-wrap: wrap;
            transition: mmslideInLeft 0.25s ease-out;
            flex-wrap: wrap;
            &.active {
                display: flex; }
            .bt-spoiler-block__item {
                flex-basis: 100%;
                margin-bottom: 20px;
                &:last-child {
                    margin-top: 0; }
                &.user_nav {
                    order: 1;
                    .menu {
                        justify-content: flex-end;
                        flex-wrap: wrap; } }
                &.main-navigation {
                    order: 2;
                    .menu {
                        justify-content: center;
                        flex-wrap: wrap; } } } } }
    .site-header {
        .main-navigation {
            .menu {
                > li {
                    margin: 0 15px 15px 0;
                    display: block;
                    flex-basis: 100%;
                    text-align: right; }

                .sub-menu {
                    display: block;
                    > .menu-item {
                        margin: 10px 15px 0 0; } } } } } }




article {
    &.page {
        margin-bottom: 45px; } }

@media (max-width: 568px) {
    .footer-top {
        .container {
            > * {
                flex: 1 1 100%;
                margin-bottom: 15px; } } } }

@media (max-width: 480px) {
    .bt-page-link_wrp {
        .bt-page-link {
            .wp-block-media-text__content {
                p {
                    font-size: 22px; } } } } }

@keyframes mmfadeIn {
    from {
        opacity: 0; }

    to {
        opacity: 1; } }

@keyframes mmfadeOut {
    from {
        opacity: 1; }

    to {
        opacity: 0; } }

@keyframes mmslideIn {
    from {
        transform: translateY(-15%); }

    to {
        transform: translateY(0); } }

@keyframes mmslideInLeft {
    from {
        transform: translateX(-15%); }

    to {
        transform: translateX(0); } }

@keyframes mmslideOut {
    from {
        transform: translateY(0); }

    to {
        transform: translateY(10%); } }

@keyframes mmslideOutLeft {
    from {
        transform: translateX(0); }

    to {
        transform: translateX(10%); } }
